import type { FC } from 'react';
import React, { useContext, Fragment } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import QueuesIcon from '@atlaskit/icon/core/pages';
import LightbulbFilledIcon from '@atlaskit/icon/core/lightbulb';
import AssetsSchemaIcon from '@atlaskit/icon-lab/core/assets-schema';

import { PageCardContext } from '../PageCardContext';

import { LastAction } from './LastAction';

const i18n = defineMessages({
	BEHAVIORAL: {
		id: 'page-card.rec.strategy.behavioral',
		defaultMessage: 'Read with this',
		description:
			'Page card recommendation is based on user behavior of when pages are viewed together in a session',
	},
	CONTENT_TO_CONTENT: {
		id: 'page-card.rec.strategy.content-to-content',
		defaultMessage: 'More like this',
		description: 'Page card recommendation is based on similarity of content between pages',
	},
	STRUCTURAL: {
		id: 'page-card.rec.strategy.structural',
		defaultMessage: 'Grouped with this',
		description:
			'Page card recommendation is based on page hierarchy (parent, child, sibling pages)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StrategyContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	color: token('color.text.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StrategyText = styled.span({
	marginLeft: token('space.100'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const Icons = {
	BEHAVIORAL: <QueuesIcon color={token('color.icon.subtle')} label="" />,
	CONTENT_TO_CONTENT: <LightbulbFilledIcon color={token('color.icon.subtle')} label="" />,
	STRUCTURAL: <AssetsSchemaIcon label="" color={token('color.icon.subtle')} />,
};

//Removed from end of page recommendations for now and may be added back later
export const RecommendationStrategy: FC = () => {
	const { recommendationStrategy } = useContext(PageCardContext);
	const validRecommendationStrategies = ['STRUCTURAL', 'BEHAVIORAL', 'CONTENT_TO_CONTENT'];
	const Icon =
		recommendationStrategy &&
		validRecommendationStrategies.includes(recommendationStrategy) &&
		Icons[recommendationStrategy];
	return recommendationStrategy &&
		validRecommendationStrategies.includes(recommendationStrategy) ? (
		<StrategyContainer>
			<Fragment>
				{Icon}
				<StrategyText>
					<FormattedMessage {...i18n[recommendationStrategy]} />
				</StrategyText>
			</Fragment>
		</StrategyContainer>
	) : (
		<LastAction />
	);
};
